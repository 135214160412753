import { colors } from "@/styles/global.styles";
import { css } from "@emotion/react";

export const mainProfileBody = css({
  fontSize: "1rem",
  position: "absolute",
  zIndex: 999,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 14, 26, 0.95)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const imageModalBackground = css({
  position: "fixed",
  zIndex: 99999,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const imageModal = css({
  width: "30em",
  height: "34em",
  backgroundColor: "#1a2026",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const imgEditor = css({
  backgroundColor: "black",
  position: "relative",
  width: "25em",
  height: "25em",
});

export const cropButton = css({
  userSelect: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "5em",
  height: "2.5em",
  borderRadius: "0.5em",
  backgroundColor: "#292e33",
  color: colors.primaryTextColor,
  cursor: "pointer",
  fontSize: "1rem",
  marginTop: "0.625em",
});

export const buttonEditRow = css({
  display: "flex",
  gap: "0.625em",
});

export const profileDiv = css({
  position: "relative",
  gap: "1.25em",
  padding: "2em",
  width: "48.75em",
  backgroundColor: "#1a2026",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const profileHeader = css({
  marginBottom: "1vh",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const title = css({
  fontSize: "1.75rem",
  color: "white",
  paddingLeft: "1vw",
});

export const closeBtn = css({
  cursor: "pointer",
  width: "1.25em",
});

export const profilePictureCol = css({
  cursor: "pointer",
  gap: "0.625em",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const profileImg = css({
  width: "7.8125em",
  height: "7.8125em",
  borderRadius: "50%",
  overflow: "hidden",
  objectFit: "cover",
});

export const addImgBtn = css({
  color: colors.primaryAccentColor,
  fontSize: "1rem",
  cursor: "pointer",
});

export const removeImgBtn = css({
  color: colors.primaryTextColor,
  background: colors.primaryAccentColor,
  width: "1.25em",
  height: "1.25em",
  display: "flex",
  justifyContent: "center",
  borderRadius: "50%",
  alignItems: "center",
  fontSize: "1rem",
  cursor: "pointer",
  position: "absolute",
  top: 0,
  left: "6em",
});

export const subHeaderRow = css({
  display: "flex",
  gap: "1em",
  width: "100%",
  alignItems: "center",
});

export const subHeader = css({
  textTransform: "uppercase",
  fontWeight: 500,
  color: colors.secondaryTextColor,
  fontSize: "0.875rem",
});

export const profileInput = css({
  borderRadius: "10px",
  height: "3.75em",
  width: "100%",
  backgroundColor: "#2e3237",
  border: "none",
  padding: "0.625em",
  "& > input": {
    color: colors.primaryTextColor,
    width: "100%",
    fontSize: "1rem",
    height: "100%",
  },
});

export const profileFooter = css({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
});

export const actionsGroup = css({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  flexDirection: "row",
  paddingTop: "1em",
});

export const signOut = css({
  fontSize: "1rem",
  cursor: "pointer",
  color: colors.primaryTextColor,
  display: "flex",
  alignItems: "center",
});

export const profileButton = css({
  userSelect: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "9.5em",
  height: "3em",
  borderRadius: "0.6em",
  backgroundColor: "#292e33",
  color: "white",
  cursor: "pointer",
  fontSize: "1rem",
  "&.redButton": {
    backgroundColor: "#dc3851",
  },
  ":disabled": {
    opacity: 0.5,
  },
});

export const errorMessage = css({
  color: colors.primaryAccentColor,
  display: "flex",
  fontSize: "0.625rem",
});

export const buttonRow = css({
  display: "flex",
  justifyContent: "space-between",
  gap: "1vw",
});
